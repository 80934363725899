import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SupportStatusPrimark, Support, SupportStatus, ViewSupportStyle, Campaign, SelectOption, StoreStatusPrimark } from '../interfaces/interfaces';
import { Icon } from '.';
import { Link } from "react-router-dom";
import { Plus } from 'react-feather';
import { RoleGuard } from './RoleGuard';
import { Role } from '../util/jwt';


export interface SupportCardProps {
    support: Support,
    showValidationIcon?: boolean,
    onVisualClick?: (image: string) => void,
    onValidateClick?: (support:Support) => void,
    onAddCampaignClick?: (support:Support) => void,
    removeCampaignSupport?: (line_id:string) => void,
    installSupport?:(line_id:string)=>void,
    duplicateSupport?:() => void,
    viewStyle?: String,
    campaign?: Campaign,
    departments?: SelectOption[],
    userRole?: Role,
    storeStatus?: StoreStatusPrimark,
    line_id?:string,
    installed?: boolean,
    store_lines?:any[];

}

type SupportCardPropsSum = SupportCardProps & WithTranslation;

class SupportCardComponent extends React.Component<SupportCardPropsSum> {

    getPrimarkStatusColor(support:Support) {
      
        switch (support.status_primark) {
            case SupportStatusPrimark.NOT_IN_CAMPAIGN: return 'fill-grey';
            case SupportStatusPrimark.PENDING_APPROVAL: return 'fill-red';
            case SupportStatusPrimark.APPROVED_BY_SM: return 'fill-yellow';
            case SupportStatusPrimark.PENDING_APPROVAL_CM: return 'fill-yellow';
            case SupportStatusPrimark.APPROVED_BY_CM: return 'fill-green';
            case SupportStatusPrimark.KENDU_PROCESSING: return 'fill-blue';
            default: return
        }
    }

    cardLink(support: Support) {
        if (support.id === undefined) {
            return '#';
        }
        return `/frames/${support.id}`;
    }
    
    isInCampaign() {
        const { support } = this.props;
        return support.status !== SupportStatus.NOT_IN_CAMPAIGN;
    }

    filterSupportLine(support:Support,role:string|undefined){
        const{store_lines}=this.props;
		let lines=store_lines;
			if(support.status_primark!==SupportStatusPrimark.NOT_IN_CAMPAIGN && role==='admin'){
				let support_line=lines?.filter(line=>line.hardware_id===support.id);
				return support_line && support_line[0]?.id;
			}else{
				return '';
			}
	
	}

    filterSupportInstalled(support:Support,role:string|undefined){
	    const{store_lines}=this.props;
		let lines=store_lines;
			if(support.status_primark!==SupportStatusPrimark.NOT_IN_CAMPAIGN && role==='admin'){
				let support_line=lines?.filter(line=>line.hardware_id===support.id);
				return support_line && support_line[0]?.descargado;
			}else{
				return false;
			}
	
	}

    renderIcon(support: Support) {
        const {  userRole, storeStatus } = this.props;

        if (userRole === Role.STORE_MANAGER && !(storeStatus === StoreStatusPrimark.N_A || storeStatus === StoreStatusPrimark.PENDING_APPROVAL_SM)) {
            return null;
        }

        if (userRole === Role.COUNTRY_MANAGER && !(storeStatus === StoreStatusPrimark.N_A || storeStatus === StoreStatusPrimark.PENDING_APPROVAL_SM || storeStatus === StoreStatusPrimark.PENDING_SIGN_OFF_CM)) {
            return null;
        }

        if (storeStatus === StoreStatusPrimark.IN_PRODUCTION || storeStatus === StoreStatusPrimark.SHIPPED || storeStatus === StoreStatusPrimark.INSTALLED || storeStatus === StoreStatusPrimark.SIGNED_OFF) {
            return null;
        }

        switch(support.status_primark) {
            case (SupportStatusPrimark.NOT_IN_CAMPAIGN):
                return  <button onClick={() => this.props.onAddCampaignClick && this.props.onAddCampaignClick(support)}>
                <Plus />
              </button>
            case (SupportStatusPrimark.PENDING_APPROVAL):
                return (
                    <button onClick={() => this.props.onValidateClick && this.props.onValidateClick(support)}>
                      <Icon type="validation" />
                    </button>
                  );
            case (SupportStatusPrimark.PENDING_APPROVAL_CM):
                return  <RoleGuard roles={[Role.COUNTRY_MANAGER, Role.ADMIN]}>  <button onClick={() => this.props.onValidateClick && this.props.onValidateClick(support)}>
                <Icon type="validation" />
              </button></RoleGuard>
            default:
                return null;
        } 
    }

    renderRemoveSupportIcon(support:Support,line_id:string|undefined){
        const {  userRole, removeCampaignSupport } = this.props;
        
        if(support.status_primark!==SupportStatusPrimark.NOT_IN_CAMPAIGN &&(userRole===Role.ADMIN|| userRole===Role.COUNTRY_MANAGER) && line_id !== undefined){

            return  <RoleGuard roles={[Role.ADMIN,Role.COUNTRY_MANAGER]}> <button onClick={() => line_id !== undefined && removeCampaignSupport?.(line_id)}><Icon type="x-icon" /></button></RoleGuard>
        }else{
            return null;
        }
    }

    getSupportImage(support:Support) {
        const { status_primark, current_image, new_image } = support;

        if (status_primark === SupportStatusPrimark.NOT_IN_CAMPAIGN) {
            return current_image;
        }

        return new_image;
    }

    renderInstallButton(support:Support,line_id:string|undefined){
        /* const token = get_user_token(); */
		/* const role = get_role(token); */
        const{userRole}=this.props;
        let installed=this.filterSupportInstalled(support,userRole)
        const {installSupport}=this.props;
        if(line_id){
            if(installed){
                return  <RoleGuard roles={[Role.ADMIN]}><button className="button--active" onClick={() => installSupport?.(line_id)}>
                <Icon type="check-icon" />
              </button>
              </RoleGuard>
            }else{
                return  <RoleGuard roles={[Role.ADMIN]}><button onClick={() => installSupport?.(line_id)}><Icon type="check-icon" /></button></RoleGuard>
            }
        }

       
    }
    renderDuplicateButton(support:Support){
        const { status_primark } = support;
        const{duplicateSupport} =this.props;

        if(status_primark===SupportStatusPrimark.PENDING_APPROVAL && !support.clon){
            return(
                <RoleGuard roles={[Role.ADMIN,Role.COUNTRY_MANAGER,Role.STORE_MANAGER, Role.BACKDOOR]}><button className="button" onClick={() => duplicateSupport?.()}>
               <Icon type="copy" />
              </button>
              </RoleGuard>
            )
        }

        if((status_primark===SupportStatusPrimark.PENDING_APPROVAL_CM)&&!support.clon){
            return(
                <RoleGuard roles={[Role.ADMIN,Role.COUNTRY_MANAGER, Role.BACKDOOR]}><button className="button" onClick={() => duplicateSupport?.()}>
               <Icon type="copy" />
              </button>
              </RoleGuard>
            )
        }
    }

    renderChildrens(){
        const { childrens } = this.props.support;
        const {  onVisualClick,  departments, t} = this.props;
        const{userRole} =this.props;
        
        if(childrens!==undefined){
     
            return(
                <ul className='multi-image-content'>
                     {childrens.map((child_support, index)=>{
                        let line_id=this.filterSupportLine(child_support,userRole);
                        const department = departments?.find(department => department.value === child_support.section)
                        let support_data=[line_id, department?.name, child_support.priority, child_support.support_type, child_support.textil_type, child_support.floor?.name];
                        if(userRole===Role.ADMIN){
                            support_data.push('NAV: '+child_support.id)
                        }else{
                            support_data.shift();
                        }
                        return(
                            <li className='multi-image-row' key={ index }>
                          <article className='element'>
                                <ul>
                                    <li>
                                        <div className="image__contain hover__enlarge" onClick={() => onVisualClick?.(this.getSupportImage(child_support))}>
                                            <img className="cursor-hand" src={this.getSupportImage(child_support)} alt="" />
                                        </div>
                                        <div className="header">
                                            <div>
                                                <h3>{ child_support.no_soporte_cliente } <span>{ child_support.width } x { child_support.height }mm</span></h3>
                                                <ul>
                                                    {support_data.filter(field => field).map(field => <li>{field}</li>)}
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        {child_support.status_primark && <em className={`state-info-box ${ this.getPrimarkStatusColor(child_support) }`}>{ child_support.status_primark==='N_A'? 'Not in Campagin (N/A)': t(child_support.status_primark.toLowerCase()) }</em>}
                                        {this.renderIcon(child_support)}
                                        {this.renderInstallButton(child_support,line_id)}
                                        {this.renderRemoveSupportIcon(child_support,line_id)}
                                    </li>
                                </ul>
                            </article>
                        </li>
                        )
                           
                          })}
                        
                    </ul>

            )
           
        }
       
        
    }
     renderSupportInfo = (fields: (string | number | undefined)[]) => {
        return fields.filter((field) => field !== undefined).map((field, index) => (
          <li key={index}>{field}</li>
        ));
      };
      
    render () {
        const { support, campaign, onVisualClick, viewStyle, departments, t,line_id} = this.props;
        const department = departments?.find(department => department.value === support.section)
        const{userRole} =this.props;
        let support_data=[line_id, department?.name, support.priority, support.support_type, support.textil_type, support.floor?.name];
        if(userRole===Role.ADMIN){
            support_data.push('NAV: '+support.id)
        }else{
            support_data.shift();
        }
        if(viewStyle === ViewSupportStyle.INDEX){
            return (
                <li>
                    <Link to={this.cardLink(support)} className="cursor-hand element">
                        <h3>{ support.no_soporte_cliente } <span>{ support.width } x { support.height }mm</span></h3>
                        { department ? department.name : support.section } · {support.priority} · {support.support_type } · {support.textil_type} . {userRole===Role.ADMIN?'NAV: '+support.id:''}
                        {support.status_primark && <span className={`${ this.getPrimarkStatusColor(support) } store-state state-info-box`}>{ t(support.status_primark.toLowerCase()) }</span>}                        
                    </Link>                    
                </li>
            );
        } else if(viewStyle === ViewSupportStyle.POPUP){
            return (  
                <article className="img-less">
                    <div className="campaign-info">
                        <div>
                            <h4>{department?.name}</h4>
                            <p>{(campaign)?campaign.title:''}</p>
                        </div>
                    </div>
                </article>
            );
        }else{
            return (                
                <article className={`element ${support.status_primark === SupportStatusPrimark.NOT_IN_CAMPAIGN ? "element--disabled" : ""}`}>
                    <ul>
                        <li>
                            <div className="image__contain hover__enlarge" onClick={() => onVisualClick?.(this.getSupportImage(support))}>
                                <img className="cursor-hand" src={this.getSupportImage(support)} alt="" />
                            </div>
                            <div className="header">
                                <div>
                                    <h3>{ support.no_soporte_cliente } <span>{ support.width } x { support.height }mm</span></h3>
                                    <ul>
                                    {this.renderSupportInfo(support_data)}

                                    </ul>
                                </div>
                            </div>
                        </li>
                                                
                        <li>                        
                           {support.status_primark && <em className={`state-info-box ${ this.getPrimarkStatusColor(support) }`}>{ support.status_primark==='N_A'? 'Not in Campagin (N/A)': t(support.status_primark.toLowerCase()) }</em>}
                           {this.renderIcon(support)}
                           {this.renderInstallButton(support,line_id)}
                           {this.renderRemoveSupportIcon(support,line_id)}
                           {this.renderDuplicateButton(support)}          
                   </li>                
                    </ul>
                    <ul>
                        {this.renderChildrens()}
                    </ul>
                </article>
            );
        }
    };
}

const SupportCard = withTranslation()(SupportCardComponent);
export { SupportCard };
