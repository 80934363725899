import React from 'react';
import { Link } from "react-router-dom";
import { Store, StoreStatusPrimark } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RoleGuard } from './RoleGuard';
import { Role } from '../util/jwt';

export interface StoreCardProps {
    store: Store
}

type StoreCardPropsSum = StoreCardProps & WithTranslation;

class StoreCardComponent extends React.Component<StoreCardPropsSum> {

    cardLink(store: Store) {
        if (store.id === undefined) {
            return '#';
        }
        return `/stores/${store.id}`;
    }

    checkDisabled(status: string) {
        if (status !== 'Passed') {
            return 'disabled';
        }
        return '';
    }

    getStatusColor() {
        const { store } = this.props;
        

        switch (store.status_primark) {
            case (StoreStatusPrimark.PENDING_APPROVAL_SM): return 'fill-red';
            case (StoreStatusPrimark.PENDING_SIGN_OFF_CM): return 'fill-yellow';
            case (StoreStatusPrimark.SIGNED_OFF): return 'fill-green';
            case (StoreStatusPrimark.IN_PRODUCTION): return 'fill-blue';
            case (StoreStatusPrimark.SHIPPED): return 'fill-blue';
            case (StoreStatusPrimark.INSTALLED): return 'fill-black';
            default: 
            return 'fill-grey';
        }
    }

    render () {
        const { t, store } = this.props;
        var primark_status = store.status_primark 
          
          
    
        return (
            <li>
                <Link to={this.cardLink(store)} className="cursor-hand">
                <h3>
                    { store.name }
                    <RoleGuard roles={[Role.ADMIN,Role.KENDU_DESIGN,Role.BACKDOOR]}>
                        { store.opening && <span> { t('opening') } </span> }
                    </RoleGuard>
                    <span>{ store.customer_shop_no }</span>
                </h3>
                      
                    <p>{ store.direccion }, { store.ciudad } ({ store.pais })</p>
                    <div className="store-state flex flex--row">
                    {store.status && <span className={`${ this.getStatusColor() }  state-info-box`}>{ t(primark_status.toLowerCase()) }</span>}
                    {store.kp_support && <span className='state-info-box  fill-grey'>{ t('kendu_processing')+': '+store.kp_support_n }</span>}
                    </div>
                </Link>
            </li>
        );
    };
}

const StoreCard = withTranslation()(StoreCardComponent);
export { StoreCard };
