import React from 'react';

export interface Props<T> {
    statuses: T[],
    status: T
    children?: React.ReactNode
}

class StatusGuardException<T> extends React.Component<Props<T>> {

    render () {
        const { statuses, status } = this.props;

        if (!statuses.includes(status)) {
            return this.props.children;
        }

        return null;
    }
}

export { StatusGuardException };