import axios from 'axios';
import { Campaign, Floor, PointOfInterest, Section, Support, VisualArea, Store, Visual, User, SpecialRequestRequest, Activity } from '../interfaces/interfaces';
import { with_auth, with_auth_check } from './helper';
import { FilterCampaignRequest, FilterFloorRequest, FilterLanguageRequest, FilterStoreRequest, FilterSupportRequest, FilterUserRequest, FilterKenduProcessingRequest, FilterActivityRequest } from './interfaces';

export * from './datatypes';


export const login = (user: string, password: string) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, { username: user, password: password }, { withCredentials: true })
    .then(response => {
        if (response.status === 201) {
            return response.data.access_token;
        }
        return null;
    })
    .catch(error => {
        console.error(error);
        return null;
    });
}

export const get_profile = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/users/me`, with_auth({withCredentials: true})).then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    }).catch(with_auth_check(error => {
        console.error(error);
        return [];
    }));
}

export const edit_user = (user: User) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/users/edit`, user, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const delete_user = (user: User) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/users/delete`, user, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const reset_user_password = (password: string, user: User) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/users/reset_user_password/${user.id}`, {password: password, hash_change_password: user.hash_change_password}, with_auth({withCredentials: true}))
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const reset_user_password_hash = (password: string, user: User) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/reset_user_password_hash/${user.id}`, {password: password, hash_change_password: user.hash_change_password },{withCredentials: true})
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const find_by_hash = (hash: string) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/find_by_hash`, {hash: hash})
    .then(response => {
        if (response.status === 201 || response.status === 200) {
            return response.data;
        }
        return null;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return null;
    }));
}

export const request_password = (email: string, subject: string, emailTitle: string, emailText: string, link_names:string[], creating?: boolean) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/request_password`, { email: email, emailText: emailText, subject: subject, emailTitle: emailTitle, link_names: link_names, creating: creating || false},{withCredentials: true} ).then(response => {
        if (response.status === 200 || response.status === 201) {
            return response.data;
        }
        return false;
    }).catch(error => {
        console.error(error);
        return false;
    });
}

export const check_available_email = (user: User) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/users/check_available_email`, {email: user.email}, with_auth())
    .then(response => {
        if (response.status === 201) {
            return response.data;
        }
        return null;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return null;
    }));
}

export const signup = (user: string, password: string)  => {
    return axios.get(`${process.env.REACT_APP_API_URL}/users/add`, with_auth()).then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return null;
    }).catch(error => {
        console.error(error);
        return null;
    });
}

export const get_stores = (params: FilterStoreRequest): Promise<Store[]> => {
    return axios.get<Store[]>(`${process.env.REACT_APP_API_URL}/stores`, with_auth({ params: params }))
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/";
        }
        return [];
    }));
}

export const one_store = (id: string, params: FilterSupportRequest) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/stores/with_supports/${id}`, with_auth({params}))
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return null;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/stores";
        }
        return null;
    }));
}

export const one_store_validation = (id: string): Promise<Store|null> => {
    return axios.get<Store>(`${process.env.REACT_APP_API_URL}/stores/validation/${id}`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return null;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/stores";
        }
        return null;
    }));
}

export const get_campaigns = (params: FilterCampaignRequest) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/campaigns`, with_auth({ params: params }))
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/";
        }
        return [];
    }));
}

export const one_campaign = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/campaigns/${id}`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return null;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/campaigns";
        }
        return null;
    }));
}

export const get_active_campaign = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/campaigns/find_active_campaign/`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return null;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return null;
    }));
}

export const one_section = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/sections/${id}`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return null;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            
        }
        return null;
    }));
}

export const get_supports = (params: FilterSupportRequest) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/hardwares`, with_auth({ params: params }))
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/";
        }
        return [];
    }));
}

export const one_support = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/hardwares/${id}`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return null;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/frames";
        }
        return null;
    }));
}

export const supports_with_visual = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/visuals/supports_with_visual/${id}`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return null;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return null;
    }));
}

export const edit_language = (language_id: string) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/users/language/${language_id}`, null, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const get_languages = (params: FilterLanguageRequest)=> {

    return axios.get(`${process.env.REACT_APP_API_URL}/languages`, with_auth({ params: params }))
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return [];
    }));
}

export const setPreviewVariables = (previewVariables : {visualAreaInPercentage: VisualArea, pointOfInterestInPercentage: PointOfInterest, chiliAssetId :string }) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/chili-publish/setPreviewVariables/`, previewVariables, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const getPreview = (previewVariables : { documentVariables:{width: Number, height: Number}, chiliAssetId :string }) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/chili-publish/getPreview/`, previewVariables, {...with_auth(),responseType: 'arraybuffer'})
    .then(response => {
        if (response.status === 201) {
            return Buffer.from(response.data, 'binary').toString('base64')
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const getSubjectInfo = ( visual : Visual ) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/chili-publish/getSubjectInfo/${ visual.chiliAssetId }`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const create_campaign = (campaign: Campaign) => {
    const campaignData = {...campaign, sections_ids: campaign.departments}
    return axios.post(`${process.env.REACT_APP_API_URL}/campaigns/add`, campaignData, with_auth())
    .then(response => {
        if (response.status === 201) {
            //check if body == false
            const dat = response.data.find((c: Campaign) => c.status_primark === 'DRAFT' && c.title === campaign.title)
            return dat;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/";
        }
        return false;
    }));
}

export const set_campaign_thumbnail = (campaign_id: string|undefined) => {    
    return axios.get(`${process.env.REACT_APP_API_URL}/campaigns/update_thumbnail/${campaign_id}`, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const edit_campaign_gallery_visible = (campaign: Campaign) => {
    const campaignData = {...campaign, sections_ids: campaign.departments}
    return axios.post(`${process.env.REACT_APP_API_URL}/campaigns/updateGalleryVisible`, campaignData, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const edit_campaign = (campaign: Campaign) => {
    const {departments, ...campaignData} = {...campaign, sections_ids: campaign.departments}
    return axios.post(`${process.env.REACT_APP_API_URL}/campaigns/edit`, campaignData, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const update_status = (campaign: Campaign) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/campaigns/update_status/${campaign.id}`,  with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const launch_campaign = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/campaigns/launch/${id}`, with_auth()).then(response => {
        if (response.status === 200) {
            return true;
        }
        return false;
    }).catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const delete_section = (section: Section) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/sections/delete`, section, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const confirm_priorities = (campaign: Campaign) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/campaigns/confirm_priorities`, campaign, with_auth())
    .then(response => {
        if (response.status === 201 || response.status === 200) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const asign_all = (campaign: Campaign) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/campaigns/asign_all`, campaign, with_auth())
    .then(response => {
        if (response.status === 201 || response.status === 200) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const get_floors = (params: FilterFloorRequest): Promise<Floor[]> => {
    return axios.get<Floor[]>(`${process.env.REACT_APP_API_URL}/floors`, with_auth({params})).then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    }).catch(with_auth_check(error => {
        console.error(error);
        return [];
    }));
}

export const create_floor = (floor: Floor) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/floors/add`, floor, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const edit_floor = (floor: Floor) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/floors/edit`, floor, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const edit_floor_order = (floor: Floor) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/floors/edit-order`, floor, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const delete_floor = (id: number) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/floors/${encodeURIComponent(id)}`, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const create_support = (support: Support) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/hardwares/add`, support, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const edit_support = (support: Support) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/hardwares/edit`, support, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const delete_support = (support: Support) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/hardwares/${support.id}`, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const update_supports = (supports: Support[]) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/hardwares/update`, supports, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}


export const check_available_support_code = (code: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/hardwares/check_available_support_code/${encodeURIComponent(code)}`, with_auth())
    .then(response => {
        return response.data;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const check_available_store_code = (code: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/stores/check_available_store_code/${code}`, with_auth())
    .then(response => {
        return response.data;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const create_store = (store: Store) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/stores/add`, store, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const edit_store = (store: Store) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/stores/edit`, store, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const create_user = (user: User) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/users/add`, user, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const generateRatiosByVisual = (visual: Visual) => {
    const params = {visual_id: visual.id, campaign_id : visual.campaign_id}
    return axios.post(`${process.env.REACT_APP_API_URL}/campaigns/generateRatiosByVisual`, params, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const add_support_to_campaign = (support_id: string) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/hardwares/add_to_campaign/${support_id}`, {}, with_auth())
    .then(response => {
        if (response.status === 200) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const add_auto_download_support = (line_id: string) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/hardwares/auto_download/${line_id}`, {}, with_auth())
    .then(response => {
        if (response.status === 200) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const remove_support_campaign=(line_id:string|undefined)=>{
    return axios.post(`${process.env.REACT_APP_API_URL}/hardwares/remove_campaign/${line_id}`, {}, with_auth())
    .then(response => {
        if (response.status === 200) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const cancel_sr = (line_id: string|undefined, campaign_id:string|undefined) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/campaigns/special_request_cancel/`, {line_id:line_id, campaign_id:campaign_id}, with_auth())
    .then(response => {
        if (response.status === 200) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const assign_visual_to_support = (visual_id: string, support_id: string) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/hardwares/assign_visual_to_support/${support_id}`, {visual_id: visual_id}, with_auth())
    .then(response => {
        if (response.status === 200) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const validate_support_image = (support_id: string) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/hardwares/validate/${support_id}`, {}, with_auth())
    .then(response => {
        if (response.status === 200) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const approve_store_sm = (store_id: string) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/stores/approve/${store_id}`, {}, with_auth())
    .then(response => {
        if (response.status === 200) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    })); 
}

export const approve_store_email = (store_id: string) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/stores/approve_store_email/${store_id}`, {}, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    })); 
}

export const store_sign_off = (store_id: string) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/stores/sign_off/${store_id}`, {}, with_auth())
    .then(response => {
        if (response.status === 200) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    })); 
}

export const install = (store_id: string) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/stores/install/${store_id}`, {}, with_auth())
    .then(response => {
        if (response.status === 200) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    })); 
}

export const admin_approve_sm = (store_id: string) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/stores/sm_install/${store_id}`, {}, with_auth())
    .then(response => {
        if (response.status === 200) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    })); 
}

export const moveUpSMStore = (store_id: string) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/stores/cmv_move_up_sm_store`, {store_id:store_id}, with_auth())
    .then(response => {
        if (response.status === 200) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    })); 
}


export const cvm_move_back_store = (store_id: string|undefined) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/stores/cvm_move_back_store`, {store_id: store_id}, with_auth())
    .then(response => {
        if (response.status === 200) {
            return true;
        }
        return false;
    }).catch(with_auth_check(error => {
        console.error(error);
        return false;
    })); 
}

export const get_gallery_visuals = (campaign_id?: string, section_id?: string, search?: string) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/visuals/get_gallery_visuals/`, {campaign_id: campaign_id, section_id: section_id, search: search }, with_auth())
    .then(response => {
        if (response.status === 200 || response.status === 201) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return [];
    })); 
}

export const get_country_managers = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/users/countrymanagers`, with_auth()).then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    }).catch(with_auth_check(error => {
        console.error(error);
        return [];
    }));
}


export const get_users = (params: FilterUserRequest): Promise<User[]> => {
    return axios.get<User[]>(`${process.env.REACT_APP_API_URL}/users`, with_auth({ params: params }))
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/";
        }
        return [];
    }));
}

export const delete_visual = (visual_id: string, campaign_id: string) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/visuals/${visual_id}/${campaign_id}`, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/";
        }
        return [];
    }));
}

export const special_request = (campaign_id: string, support_id: string, special_request: SpecialRequestRequest) => {
	return axios.post(`${process.env.REACT_APP_API_URL}/campaigns/special_request/${campaign_id}/${support_id}`, special_request, with_auth())
	.then(response => {
		if (response.status === 201) {
			return true;
		}
		return false;
	})
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/";
        }
        return [];
    }));
}

export const download_csv = (campaign_id: string, visual_id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/hardwares/download_csv/${campaign_id}/${visual_id}`, with_auth())
	.then(response => {
		return response.data;
	})
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/";
        }
        return [];
    }));
}

export const set_adaptation = (visual_id: string, enabled: boolean) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/visuals/adaptation/${visual_id}/${enabled}`, {}, with_auth())
    .then(response => {
        if (response.status === 200) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    })); 
}

export const processing = (params: FilterKenduProcessingRequest) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/campaigns/processing/active`, with_auth( {params: params }))
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    })); 
}
export const processing_total_lines = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/campaigns/processing/active/total`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    })); 
}

export const processingDetails = (campaignId: string|undefined, supportId: string|undefined) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/campaigns/processing/${campaignId}/${supportId}`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
}

export const upload_aaff = (campaign_id: string, support_id: string, file: File) => {
	const formData = new FormData();
	formData.append('image', file);

    return axios.post(`${process.env.REACT_APP_API_URL}/campaigns/processing/upload_image/${campaign_id}/${support_id}`, formData, with_auth({
		headers: { 'Content-Type': 'multipart/form-data'}
	})).then(response => {
		if (response.status === 201) {
			return true;
		}
		return false;
	})
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    })); 
}

export const upload_aaff_kendu = (campaign_id: string, support_id: string, formData: FormData) => {
    console.log(`Enviando archivos a campaña ${campaign_id}, soporte ${support_id}`);
    
    return axios.post(`${process.env.REACT_APP_API_URL}/campaigns/processing/upload_multiple_files/${campaign_id}/${support_id}`, formData, with_auth({
		headers: { 'Content-Type': 'multipart/form-data'}
	})).then(response => {
		if (response.status === 201) {
            console.log("Resultados del procesamiento:", response.data);
            // Si todos los archivos se procesaron correctamente
            if (response.data.processedFiles === response.data.totalFiles) {
                return { success: true, data: response.data };
            } else {
                // Si alguno falló, aún retornamos éxito pero con advertencia
                return { 
                    success: true, 
                    warning: true,
                    message: `${response.data.processedFiles} de ${response.data.totalFiles} archivos procesados correctamente`,
                    data: response.data 
                };
            }
		}
        console.log("Respuesta del servidor no esperada:", response.status);
		return { success: false, message: "Estado de respuesta inesperado" };
	})
    .catch(with_auth_check(error => {
        console.error("Error al enviar archivos:", error);
        return { 
            success: false, 
            message: error.response?.data?.message || "Error al enviar documentos" 
        };
    }));
}

export const changeState = (supportId: string, campaignId: string, newState: string) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/hardwares/update_status`, {supportId, campaignId, state: newState}, with_auth())
    .then(response => {
        if (response.status === 200) {
            return true;
        } else return false
    })
}

export const cancelSpecialRequest = (lineId: string, campaignId: string) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/campaigns/special_request_cancel`, {campaignId, lineId}, with_auth())
    .then(response => {
        if (response.status === 200) {
            return true;
        } else return false
    })
}

export const findVisual = (id: string, campaignId: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/visuals/single/${id}/${campaignId}`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return undefined;
    })
}


export const get_activity_types = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/activities/activity_types`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return undefined;
    })
}

export const get_activities = (params: FilterActivityRequest): Promise<Activity[]> => {
  
    return axios.get<Activity[]>(`${process.env.REACT_APP_API_URL}/activities`, with_auth({ params: params }))
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    });
    
}

export const get_total_pages = (params: FilterActivityRequest): Promise<number> => {
  
    return axios.get(`${process.env.REACT_APP_API_URL}/activities/pages`, with_auth({ params: params }))
    .then(response => {
        if (response.status === 200) {
            return response.data;   
        }
        return [];
    });
    
}


export const update_support_info = (line_id: string|undefined, priority: string, department: string,campaign_id:string|undefined, support_id:string|undefined) => {
	

    return axios.post(`${process.env.REACT_APP_API_URL}/hardwares/update_line_info`, {line_id, priority, department,campaign_id,support_id}, with_auth())
    .then(response => {
        if (response.status === 200) {
            return true;
        } else return false
    })
}

export const clone_support = (support_id:string|null) => {
	

    return axios.post(`${process.env.REACT_APP_API_URL}/hardwares/clone_support`, {support_id}, with_auth())
    .then(response => {
        if (response.status === 201) {
            return response.data;
        } else return false
    })
}


export const update_support_kendu_processing = (campaign_id?:string,line_id?:string,hardware_id?: string, section_id?: string, priority?: string) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/hardwares/update_support_kendu_processing/`, {campaign_id:campaign_id,line_id: line_id, section_id: section_id, hardware_id: hardware_id,priority:priority }, with_auth())
    .then(response => {
        if (response.status === 200 || response.status === 201) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return [];
    })); 
}


export const update_visual_priority = (campaign_id?:string, section_id?: string,visual_id?:string, priority?: number) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/visuals/update_priority/`, {campaign_id:campaign_id,visual_id: visual_id, section_id: section_id,priority:priority }, with_auth())
    .then(response => {
        if (response.status === 200 || response.status === 201) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return [];
    })); 
}

export const update_store_status = (store_id?: string, status?: string) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/stores/update_status/${store_id}`, {status: status}, with_auth())
    .then(response => {
        if (response.status === 200 || response.status === 201) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return [];
    })); 
}

export const update_store_opening = (store_id?: string, opening?: boolean) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/stores/update_opening/${store_id}`, {opening: opening}, with_auth())
    .then(response => {
        if (response.status === 200 || response.status === 201) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return [];
    }));
}


