import { Action, combineReducers } from 'redux';
import AuthReducer from '../reducers/AuthReducer';
import DatatypesReducer from '../reducers/datatypes/DatatypesReducer';
import CampaignIndexReducer from '../screens/campaign/index/CampaignIndexReducer';
import CampaignDetailReducer from '../screens/campaign/detail/CampaignDetailReducer';
import StoreIndexReducer from '../screens/store/index/StoreIndexReducer';
import StoreDetailReducer from '../screens/store/detail/StoreDetailReducer';
import StoreValidateImagesReducer from '../screens/store/validate_images/ValidateImagesReducer';
import StoreValidateCropsReducer from '../screens/cropValidation/validate_crops/ValidateCropsReducer';
import SupportIndexReducer from '../screens/frame/index/SupportIndexReducer';
import SupportDetailReducer from '../screens/frame/detail/SupportDetailReducer';
import GalleryIndexReducer from '../screens/gallery/index/GalleryIndexReducer';
import GalleryDetailReducer from '../screens/gallery/detail/GalleryDetailReducer';
import UserIndexReducer from '../screens/users/index/UserIndexReducer';
import { ThunkAction } from 'redux-thunk';
import IncidentIndexReducer from '../screens/incident/index/IncidentIndexReducer';
import SecurityLogin from '../screens/security/login/LoginReducer';
import SecurityPasswordRequest from '../screens/security/password-request/PasswordRequestReducer';
import SecurityPasswordReset from '../screens/security/password-reset/PasswordResetReducer';
import SecuritySignUp from '../screens/security/sign-up/SignUpReducer';
import DashboardIndexReducer from '../screens/dashboard/index/DashboardIndexReducer';
import ProcessingIndexReducer from '../screens/kenduProcessing/index/ProcessingIndexReducer';
import ProcessingDetailsReducer from '../screens/kenduProcessing/detail/ProcessingDetailsReducer';
import StoreApprove from '../screens/store/approve/StoreAproveReducer';
import ActivityIndexReducer from '../screens/activity/index/ActivityIndexReducer';
import processingReducer from '../reducers/processingReducer';

const rootReducer = combineReducers({
    auth: AuthReducer,
    datatypes: DatatypesReducer,
    campaignIndex: CampaignIndexReducer,
    campaignDetail: CampaignDetailReducer,
    storeIndex: StoreIndexReducer,
    storeDetail: StoreDetailReducer,
    storeValidateImages: StoreValidateImagesReducer,
    StoreValidateCrops: StoreValidateCropsReducer,
    storeApprove:StoreApprove,
    supportIndex: SupportIndexReducer,
    supportDetail: SupportDetailReducer,
    galleryIndex: GalleryIndexReducer,
    galleryDetail: GalleryDetailReducer,
    incidentIndex: IncidentIndexReducer,
    securityLogin: SecurityLogin,
    securityPasswordRequest: SecurityPasswordRequest,
    securityPasswordReset: SecurityPasswordReset,
    securitySignUp: SecuritySignUp,
    userIndex: UserIndexReducer,
	dashboardIndex: DashboardIndexReducer,
	processingIndex: ProcessingIndexReducer,
    processingDetail: ProcessingDetailsReducer,
    activityIndex: ActivityIndexReducer,
    processing: processingReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>

export default rootReducer;
