import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Filter as FilterIcon } from 'react-feather';
import { pluralize } from '../util/text';
import { SecondaryButton } from '.';
import { SecondaryButtonStyle, Filter as FilterInterface } from "../interfaces/interfaces";
import { Switch } from '@material-ui/core';

export interface FilterRowSwitchProps {
    cssClass?: string,
    filter: FilterInterface,
    results?: number,
    totalResults?: number,
    pluralizeStrings: string[],
    onFilterClicked: () => void,
    active: boolean,
}

type FilterRowSwitchPropsSum = FilterRowSwitchProps & WithTranslation & { setMessage: (message: boolean) => void };

interface FilterRowSwitchState {
    active: boolean;
}

class FilterRowSwitchComponent extends React.Component<FilterRowSwitchPropsSum, FilterRowSwitchState> {
    constructor(props: FilterRowSwitchPropsSum) {
        super(props);
        this.state = {
            active: props.active,
        };
    }

    is_filtered(filter: any) : boolean {
        return Object.values(filter).some(item => item && item !== null && item !== '');
    }

    render () {
        const { t, filter, results, totalResults, pluralizeStrings, cssClass, onFilterClicked } = this.props;
        const line = {id: 'filterRowSwitch', name: 'filterRowSwitch'};


        var numberComponent = undefined
        if (results && results === totalResults) {
            numberComponent = <p>{ results + " / " + pluralize(totalResults, pluralizeStrings)}</p>
        } else if (results && totalResults && results !== totalResults) {
            numberComponent =  <p>{ results + " / " + pluralize(totalResults, pluralizeStrings)}</p>
        }

        return (
            <article className={cssClass}>
                <div className="resultDetails">
                    {numberComponent}
                </div>
                <div className="filters" style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                    <label>{ this.state.active ? t('modo_kendu') : t('modo_normal') }</label>
                    <Switch id={ line.id } checked={ this.state.active } onChange={(event) => {
                            const isChecked = event.target.checked;
                            this.setState({active: isChecked});
                            this.props.setMessage(isChecked);
                        }
                    }/>
                
                    <SecondaryButton onClick={() => onFilterClicked()} cssClass={'flex col-center gap-4'} style={this.is_filtered(filter) ? SecondaryButtonStyle.YELLOW : SecondaryButtonStyle.PRIMARY}>
                        <FilterIcon />
                        <span>{t('filter')}</span>
                    </SecondaryButton>
                </div>
            </article>
        );
    }

}

const FilterRowSwitch = withTranslation()(FilterRowSwitchComponent);
export { FilterRowSwitch };
