import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import { ContentTop, Spinner, Icon,Filter, FilterRowSwitch } from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FilterFields } from '../../../interfaces/interfaces';
import { getActiveCampaign, getLines,cleanLines,updateFilter,clearFilter,getStores } from './ProcessingIndexActions';
import { getSupportTypes, getTextileTypes, getDepartments,getProductionLanguages, getAllLanguages } from '../../../reducers/datatypes/DatatypesActions';
import { Link } from 'react-router-dom';
import { setMessage } from '../../../actions/processingActions';


const mapStateToProps = (state: RootState) => {
	return {
		processing_index_state: state.processingIndex,
		data_types_state: state.datatypes,
		message: state.processing.message,
	}
}
const connector = connect(mapStateToProps, {
	getActiveCampaign,
	getLines,
	cleanLines,
	getTextileTypes,
	getSupportTypes,
	getDepartments,
	getProductionLanguages,
	updateFilter,
	clearFilter,
	getStores,
	getAllLanguages,
	setMessage
	
});

type Props = ConnectedProps<typeof connector> & WithTranslation;

interface State {
	showFilter: boolean,
	active: boolean,
}
class Index extends React.Component<Props,State> {

	constructor(props: Props) {
		super(props);

		// Recuperar estado guardado de localStorage al inicializar el componente
		const savedMessage = localStorage.getItem('kendu_global_message');
		const initialMessage = savedMessage !== null ? savedMessage === 'true' : this.props.message;
		
		this.state = {
			showFilter: false,
			active: initialMessage
		};
	}

	componentDidMount() {
		this.props.cleanLines();
		this.props.getLines();
		
		 // Sincronizar el estado global con localStorage
		const savedMessage = localStorage.getItem('kendu_global_message');
		if (savedMessage !== null) {
			const isMessageTrue = savedMessage === 'true';
			
			// Actualizar el estado global solo si es diferente
			if (isMessageTrue !== this.props.message) {
				this.props.setMessage(isMessageTrue);
			}
		} else {
			// Si no hay valor guardado, inicializar localStorage con el valor actual
			localStorage.setItem('kendu_global_message', String(this.props.message));
		}
		
		this.props.getTextileTypes();
		this.props.getSupportTypes();
		this.props.getDepartments();
		this.props.getProductionLanguages();
		this.props.getStores();
		this.props.getAllLanguages();

		// Añadir evento para escuchar cambios en localStorage de otras pestañas
		window.addEventListener('storage', this.handleStorageChange);
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.message !== this.props.message) {
			this.setState({ active: this.props.message });
			
			// Guardar cambio en localStorage
			localStorage.setItem('kendu_global_message', String(this.props.message));
		}
	}

	componentWillUnmount(){
		this.props.cleanLines();
		window.removeEventListener('storage', this.handleStorageChange);
	}

	// Método para manejar cambios de localStorage en otras pestañas
	handleStorageChange = (event: StorageEvent) => {
		if (event.key === 'kendu_global_message') {
			const newValue = event.newValue === 'true';
			if (newValue !== this.props.message) {
				this.props.setMessage(newValue);
			}
		}
	}

	// Función mejorada para manejar el cambio del switch
	handleSetMessage = (value: boolean) => {
		// Actualizar el estado global y localStorage
		this.props.setMessage(value);
		// Ya no es necesario establecer localStorage aquí porque se hace en la acción
		
		console.log('Switch changed to:', value);
	}

	renderContentTop() {
		const { t } = this.props;
		const { showFilter } = this.state;
		const { filter, lines, totalLines } = this.props.processing_index_state;
		let contLines=0;
		if(Array.isArray(lines)){
			lines?.map((line:any)=>{
				
					contLines=contLines+1;
				
			});
		}
		return (
			<ContentTop>
				<article className="detail-top">
					<div>
						<h2>{ t('Kendu processing') }</h2>
					</div>
				</article>

				 {/* Pasamos la función personalizada en lugar de setMessage directamente */}
				<FilterRowSwitch
					setMessage={this.handleSetMessage}		
					filter={filter}
					results={contLines|| 0}
					totalResults={totalLines?.length || 0}
					pluralizeStrings={[t('result_found'), t('results_found')]}
					onFilterClicked={() => this.setState({ showFilter: !showFilter })}
					active={this.state.active} // Pasar el estado activo al componente FilterRowSwitch
				/>
			</ContentTop>
		)
	}

	renderProcessingList() {
		const { lines } = this.props.processing_index_state;
		const { t } = this.props;
		if (lines?.length === 0) {
			return (
				<article className='noResult'>
					<p>{t('no_lines')}</p>
				</article>
			);
		}

		return (
			<>
				<section id="listPills">
					<ul>
					{Array.isArray(lines) && lines?.sort((a, b) => new Date(a.line.sr_date.replace("T00:00:00.000Z","")) > new Date(b.line.sr_date.replace("T00:00:00.000Z",""))   ? 1 : -1).map(({ line, store}) => {
						return(
							<>
							{ store!==undefined&& line!==null&&
							<li key={`${line.campaign_id}${line.hardware_id}${line.visual_id}`}>
							<Link to={`/processing_detail/${line.campaign_id}/${line.hardware_id}`} className="cursor-hand flex row-space-between">
								<div>
									<h3>
										{store.name} 
										<span>{store.customer_shop_no}</span>
									</h3>
									<ul className='list__inline__dot'>
										<li>Line {line.id}</li>
										<li>{line.support_width}x{line.support_height} mm</li>
										<li>{line.sr_date.replace("T00:00:00.000Z","")} </li>
										
									</ul>
								</div>
								<div className='flex col-center gap-8'>
									<span className='state state--gray'>{ t('Support number:') } {line.nombresoporte}</span>
									{line.user_rol && <span className='state state--gray' >{t(line.user_rol)}</span>}
									{line.directory2 && <span className='state state--gray' style={{'width':'100px'}}>{t('Role:')} {line.directory2.language}</span>}
									{line.special_request && <span className='state state--red'>{ t('Special Request') }</span>}
									{line.adaptation && <span className='state state--yellow'>{ t('Adaptation') }</span>}
									{line.directory2 && <span className='state state--blue'>{ t('Directory') }</span>}

									<Icon type="chevron-right" />
								</div>
							</Link>
						</li>}
							
							</>
						)
							
						
					})}
					</ul>
				</section>
			</>
		)
	}

	renderFilter() {
		const { showFilter } = this.state;
		const { filter, stores } = this.props.processing_index_state;
		
		const { departments, textile_types, support_types,languages } = this.props.data_types_state;
	
		if (showFilter) {
			return (
				<Filter
					filterFields={FilterFields.KENDU_PROCESSING}
					filter={filter}
					textile_types={textile_types}
					support_types={support_types}
					sections={departments}
					stores={stores}
					languages={languages}

					
					onClose={() => this.setState({ showFilter: !showFilter })}
					onClear={() => {
						this.props.clearFilter();
						this.setState({ showFilter: !showFilter })
						this.props.getLines();
					}}
					onSubmit={(filter) => {
						this.props.updateFilter(filter);
						this.setState({ showFilter: !showFilter })
						this.props.getLines();
					}}
				/>
			);
		}
	}

	render () {
		const { t } = this.props;
		const { loading,lines } = this.props.processing_index_state;
		if (loading || lines===undefined) {
			return <Spinner message={ t('loading') } />;
		}

		return ( 
			<>
			{this.renderFilter()}
				{this.renderContentTop()}
				{this.renderProcessingList()}
			</>
		);
	}
}

export default withTranslation()(connector(Index));
