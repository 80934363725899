// frontend/src/reducers/processing/processingReducer.ts
// Obtener el valor inicial desde localStorage si existe
const getInitialMessage = (): boolean => {
  const savedMessage = localStorage.getItem('kendu_global_message');
  return savedMessage !== null ? savedMessage === 'true' : false;
};

const initialState = {
  message: getInitialMessage()
};

const processingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SET_MESSAGE':
      return {
        ...state,
        message: action.payload
      };
    default:
      return state;
  }
};

export default processingReducer;
