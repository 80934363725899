import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../reducers";
import { get_role,Role } from '../../../util/jwt';
import { get_user_token } from '../../../util/cookies';
import {
  ContentTop,
  Spinner,
  ConfirmationPopUp,
  EditSupportKenduProcessing,
} from "../../../components";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  getData,
  markAsSolved,
  updateDeparmentValue,
  updatePriority,
  updateSupportInfo,
} from "./ProcessingDetailsActions";
import { RouteComponentProps } from "react-router-dom";
import { upload_aaff, upload_aaff_kendu, remove_support_campaign,update_support_kendu_processing,cancel_sr } from "../../../api";
import {
  getProductionLanguages,
  getDepartments,
} from "../../../reducers/datatypes/DatatypesActions";
import { Plus } from "react-feather";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone, {
  IExtra,
  IFileWithMeta,
  StatusValue,
} from "react-dropzone-uploader";

import { SelectOption } from "../../../interfaces/interfaces";
import { RoleGuard } from "../../../components/RoleGuard";
import { setMessage } from '../../../actions/processingActions';
const mapStateToProps = (state: RootState) => {
  return {
    processing_details_state: state.processingDetail,
    data_types_state: state.datatypes,
    message: state.processing.message,
  };
};
const connector = connect(mapStateToProps, {
  getData,
  markAsSolved,
  getProductionLanguages,
  getDepartments,
  updateDeparmentValue,
  updatePriority,
  updateSupportInfo,
  setMessage
});

interface ParamTypes {
  campaign_id: string;
  support_id: string;
}

type Props = RouteComponentProps<ParamTypes> &
  ConnectedProps<typeof connector> &
  WithTranslation;

interface State {
  showFilter: boolean;
  uploading: boolean;
  api_loading: boolean;
  showRemoveConfirmation: boolean;
  showCancelSpecialRequest: boolean;
  showEditSupport: boolean;
  showImage: boolean;
  kenduImageFile: IFileWithMeta | null;
  kenduPdfFile: IFileWithMeta | null;
  showImageUploadMessage: boolean;
  showPdfUploadMessage: boolean;
}

class Index extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showFilter: false,
      uploading: false,
      api_loading: false,
      showRemoveConfirmation: false,
      showCancelSpecialRequest: false,
      showEditSupport: false,
      showImage: false,
      kenduImageFile: null,
      kenduPdfFile: null,
      showImageUploadMessage: false,
      showPdfUploadMessage: false,
    };
  }

  componentDidMount() {
    this.props.getData(
      this.props.match.params.campaign_id,
      this.props.match.params.support_id
    );
    this.props.getProductionLanguages();
    this.props.getDepartments();
    
    // Sincronizar el valor de message con el almacenado en localStorage
    const savedMessage = localStorage.getItem('kendu_global_message');
    if (savedMessage !== null && (savedMessage === 'true') !== this.props.message) {
      this.props.setMessage(savedMessage === 'true');
    }
  }

  // Función auxiliar para obtener el valor de kendu_global_message del localStorage
  getKenduGlobalMessage(): boolean {
    const storedValue = localStorage.getItem('kendu_global_message');
    return storedValue === 'true'; // Convierte el string a booleano
  }

  // Imagen seleccionada del input
  imageSelected(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files.length > 0) {
      const { data } = this.props.processing_details_state;

      const file = event.target.files.item(0)!;

      this.setState({ 
        uploading: true,
        showImageUploadMessage: false 
      });
      upload_aaff(data!.line.campaign_id, data!.support.id, file).then(() => {
        this.setState({ 
          uploading: false,
          showImageUploadMessage: true 
        });
        
        // Recargar los datos después de mostrar el mensaje
        setTimeout(() => {
          this.props.getData(
            this.props.match.params.campaign_id,
            this.props.match.params.support_id
          );
        }, 1500);
      });
    }
  }

  // Método modificado para mostrar el mensaje sin temporizador
  showFileUploadMessage = (fileType: 'image' | 'pdf') => {
    if (fileType === 'image') {
      this.setState({ showImageUploadMessage: true });
    } else if (fileType === 'pdf') {
      this.setState({ showPdfUploadMessage: true });
    }
  }

  // Imagen seleccionada del input para Kendu - Modificado para manejar tipos
  imageSelectedKendu(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files.item(0)!;
      
      // Creamos el objeto de metadatos para el archivo
      const newFile: IFileWithMeta = {
        file,
        meta: {
          status: 'ready',
          previewUrl: URL.createObjectURL(file),
          id: "",
          type: "",
          name: "",
          uploadedDate: "",
          percent: 0,
          size: 0,
          lastModifiedDate: ""
        },
        cancel: () => {},
        restart: () => {},
        remove: () => {},
      };
      
      // Determinamos si es una imagen o un PDF
      const fileType = file.type.startsWith('image/') ? 'image' : 'pdf';
      
      if (fileType === 'image') {
        // Aseguramos que los mensajes se actualicen correctamente
        this.setState({ 
            kenduImageFile: newFile, 
            showImageUploadMessage: false 
        }, () => {
            // Mostramos el mensaje después de actualizar el estado
            this.setState({ showImageUploadMessage: true });
        });
      } else if (fileType === 'pdf') {
        // Aseguramos que los mensajes se actualicen correctamente
        this.setState({ 
            kenduPdfFile: newFile, 
            showPdfUploadMessage: false 
        }, () => {
            // Mostramos el mensaje después de actualizar el estado
            this.setState({ showPdfUploadMessage: true });
        });
      }
    }
  }

  // Imagen seleccionada arrastrando
  handleUpload = (file: IFileWithMeta, status: StatusValue) => {
    if (status === "done") {
      let upload_file = file.file;
      const { data } = this.props.processing_details_state;
      this.setState({ 
        uploading: true,
        showImageUploadMessage: false 
      });
      upload_aaff(data!.line.campaign_id, data!.support.id, upload_file).then(
        () => {
          // Mostrar mensaje de éxito antes de recargar
          this.setState({ 
            uploading: false,
            showImageUploadMessage: true 
          });
          
          // Recargar después de un tiempo para que se vea el mensaje
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      );
    }
  };

  handleKenduUpload = (file: IFileWithMeta, status: StatusValue) => {
    // Aceptamos archivos incluso si ya hay uno subido anteriormente
    if (status === "ready" || status === "done") {
      // Determinamos si es una imagen o un PDF
      const fileType = file.file.type.startsWith('image/') ? 'image' : 'pdf';
      
      if (fileType === 'image') {
        // Primero limpiamos el estado previo antes de establecer el nuevo
        this.setState({ 
          kenduImageFile: null, 
          showImageUploadMessage: false 
        }, () => {
          // Una vez limpio el estado, configuramos el nuevo archivo
          this.setState({ 
            kenduImageFile: file 
          }, () => {
            // Mostramos el mensaje después de actualizar el estado
            this.setState({ showImageUploadMessage: true });
          });
        });
      } else if (fileType === 'pdf') {
        // Primero limpiamos el estado previo antes de establecer el nuevo
        this.setState({ 
          kenduPdfFile: null, 
          showPdfUploadMessage: false 
        }, () => {
          // Una vez limpio el estado, configuramos el nuevo archivo
          this.setState({ 
            kenduPdfFile: file 
          }, () => {
            // Mostramos el mensaje después de actualizar el estado
            this.setState({ showPdfUploadMessage: true });
          });
        });
      }
    }
  };

  handleSendData = () => {
    const { data } = this.props.processing_details_state;
    const { t } = this.props;
    
    // Verificamos si hay al menos un archivo para enviar
    if (!this.state.kenduImageFile && !this.state.kenduPdfFile) {
        alert(t("no_files_error"));
        return;
    }
    
    // Crear un FormData con todos los archivos
    const formData = new FormData();
    
    // Primero agregamos la imagen (si existe)
    if (this.state.kenduImageFile) {
        formData.append('image', this.state.kenduImageFile.file);
    }
    
    // Luego agregamos el PDF (si existe)
    if (this.state.kenduPdfFile) {
        formData.append('image', this.state.kenduPdfFile.file);
    }
    
    // Mostrar al usuario que estamos procesando
    this.setState({ uploading: true });
    
    // Enviar todos los archivos
    upload_aaff_kendu(data!.line.campaign_id, data!.support.id, formData)
        .then((response) => {
            this.setState({ 
                uploading: false, 
                kenduImageFile: null, 
                kenduPdfFile: null,
                showImageUploadMessage: false,
                showPdfUploadMessage: false
            });
            
            if (response.success) {
                if (response.warning) {
                    // Si algunos archivos fallaron pero otros tuvieron éxito
                    alert(t("file_upload_warning"));
                    setTimeout(() => {
                      window.location.reload();
                  }, 500);
                }

                // Recargar la página para mostrar los cambios
                window.location.reload();
                this.props.getData(
                  this.props.match.params.campaign_id,
                  this.props.match.params.support_id
                );
            } else {
                // Si hubo un error general
                alert(t("file_upload_error"));
                // Recargar la página después de mostrar el error
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            }
        })
        .catch((error) => {
            console.error("❌ Error al enviar los documentos:", error);
            this.setState({ uploading: false });
            alert(t("file_upload_error"));

            // Recargar la página después de mostrar el error
            setTimeout(() => {
                window.location.reload();
            }, 500);
        });
  };

  inputContent(extra: IExtra) {
    const { t } = this.props;
    if (extra.reject) {
      return this.renderInputContent(t("only_file_type_allowed"));
    }
    return this.renderInputContent(t("start_dragging_or_attaching_the_images"));
  }

  renderInputContent(text: string) {
    return (
      <div key="1">
        {" "}
        {/* TODO CHRISTIAN: la preview de imagenes al subir: ( empequeñecer las imagenes) y alinearla con la barra de progreso (clases dzu-previewContainer , dzu-previewImage ) */}
        <article className="drag-and-drop-placeholder">
          {" "}
          {/* TODO CHRISTIAN: esta capa tiene que ocupar todo el tamaño de la capa del padre para poder hacer drag and drop bien, si pongo width y height al 100% luego hay que centrar el texto, poner tambien el cursor bonito) y el simbolo "+" en gris*/}
          <div className="placeholder-icon">
            <Plus />
          </div>
          <p className="placeholder-text">{text}</p>
        </article>
      </div>
    );
  }

  renderImage() {
    const { t } = this.props;
    const { visual, data } = this.props.processing_details_state;
    if (this.state.uploading) {
      return <Spinner message={t("uploading_aaff")} />;
    }

    if (data?.support.new_image !== undefined || visual) {
      return (
        <>
          {/* TODO -> Desarrollo: 'La clase hover__enlarge aparecerá solo cuando contenga una imagen y esta se pueda ampliar' */}
          <div className={`specia__request--image hidden-preview hover__enlarge ${(data?.support.new_image !== undefined || visual) ? 'image-uploaded' : ''}`}>
          {data?.line?.special_request_image && (
              <ul className="tab-bar">
                <li className={!this.state.showImage ? "active" : ""} onClick={() => this.setState({ showImage: false })}>
                  {t('Kendu')}
                </li>
                <li className={this.state.showImage ? "active" : ""} onClick={() => this.setState({ showImage: true })}>
                  {t('Proposal')}
                </li>
              </ul>
          )}

          
            {/* TODO -> Desarrollo: 'Si no hay imagen, se pone el siguiente div' */}
            {data?.support.new_image ? (
              <img className="cursor-hand" src={`${data?.support.new_image}?v=${new Date().getTime()}`} alt="" />
            ) : (
              this.renderInputContent(t("start_dragging_or_attaching_the_images"))
            )}
            <Dropzone
              onChangeStatus={this.handleUpload}
              accept="application/pdf, image/*"
              maxFiles={1}
              styles={{
                dropzone: {
                  borderRadius: 0,
                  borderWidth: 0,
                  borderStyle: "dashed",
                  borderColor: "#d5d9e4",
                  overflow: "hidden",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                },
                dropzoneReject: { borderColor: "red", backgroundColor: "red" },
                dropzoneActive: {
                  borderColor: "#00a3da",
                  backgroundColor: "#00a3da",
                },
                inputLabel: {
                  color: "#00a3da",
                  fontFamily: "Inter",
                  fontSize: "1.6rem",
                  display: "none",
                },
                inputLabelWithFiles: { display: "none" },
                input: { display: "none" },
              }}
              inputContent={(file, extra) => this.inputContent(extra)}
            />

            <div className="upload-button-content">
              <p className="upload-button-content__title">{t("upload_image")}</p>
              <p className="upload-button-content__description">{t("upload_image_frame")}</p>
              <input
                type="file"
                id="file"
                onChange={(event) => this.imageSelected(event)}
                style={{ display: "none" }}
                accept=".pdf"
              />
              <button className="btn-base fill-black link-s">
                <label htmlFor="file">
                  {this.state.showImageUploadMessage ? t("Change Image") : t("upload_image")}
                </label>
              </button>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          {/* TODO -> Desarrollo: 'La clase hover__enlarge aparecerá solo cuando contenga una imagen y esta se pueda ampliar' */}
          <div className="specia__request--image hover__enlarge">
          {data?.line?.special_request_image && (
              <ul className="tab-bar">
                <li className={!this.state.showImage ? "active" : ""} onClick={() => this.setState({ showImage: false })}>
                  {t('Kendu')}
                </li>
                <li className={this.state.showImage ? "active" : ""} onClick={() => this.setState({ showImage: true })}>
                  {t('Proposal')}
                </li>
              </ul>
          )}
            {/* TODO -> Desarrollo: 'Si no hay imagen, se pone el siguiente div' */}
            <Dropzone
              onChangeStatus={this.handleUpload}
              accept="application/pdf, image/*"
              maxFiles={1}
              inputContent={(file, extra) => this.inputContent(extra)}
              styles={{
                dropzone: {
                  borderRadius: 0,
                  borderWidth: 0,
                  borderStyle: "dashed",
                  borderColor: "#d5d9e4",
                  overflow: "hidden",
                  width: "100%",
                  height: "100%",
                },
                dropzoneReject: { borderColor: "red", backgroundColor: "red" },
                dropzoneActive: {
                  borderColor: "#00a3da",
                  backgroundColor: "#00a3da",
                },
                inputLabel: {
                  color: "#00a3da",
                  fontFamily: "Inter",
                  fontSize: "1.6rem",
                },
                inputLabelWithFiles: { display: "none" },
                input: { display: "none" },
              }}
            />
          </div>
          <div className="flex row-flex-end">
            <input
              type="file"
              id="file"
              onChange={(event) => this.imageSelected(event)}
              style={{ display: "none" }}
              accept=".pdf"
            />
            <button className="btn-outline outline-primary link-m">
              <label htmlFor="file">{t("upload_image")}</label>
            </button>
          </div>
        </>
      );
    }
  }

  renderKenduImage() {
    const { t } = this.props;
    const { visual, data } = this.props.processing_details_state;

    if (data?.support.new_image !== undefined || visual) {
      return (
        <>
          <div className="specia_request_row gap-16" style={{ display: 'flex', alignItems: 'center'}} >
            {/* PDF */}
            <div style={{ width:'100%', maxWidth: '300px', maxHeight: '300px', }} className={`specia__request--image hidden-preview hover__enlarge ${(data?.support.new_image !== undefined || visual) ? 'image-uploaded' : ''}`}>

              {this.state.showPdfUploadMessage ? (
                <div className="upload-success-message bg-success" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <span>{t("file_upload_correctly")}</span>
                </div>
              ) : (
                data?.support.new_image ? (
                  <img className="cursor-hand" src={`${data?.support.new_image}?v=${new Date().getTime()}`} alt="" />
                ) : (
                  this.renderInputContent(t("upload_pdf"))
                )
              )}
              <Dropzone 
                onChangeStatus={this.handleKenduUpload}
                getUploadParams={() => ({ url: 'no-upload' })}
                autoUpload={false}
                accept="application/pdf"
                maxFiles={1}
                styles={{
                dropzone: {
                  borderRadius: 0,
                  borderWidth: 0,
                  borderStyle: "dashed",
                  borderColor: "#d5d9e4",
                  overflow: "hidden",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                },
                dropzoneReject: { borderColor: "red", backgroundColor: "red" },
                dropzoneActive: {
                  borderColor: "#00a3da",
                  backgroundColor: "#00a3da",
                },
                inputLabel: {
                  color: "#00a3da",
                  fontFamily: "Inter",
                  fontSize: "1.6rem",
                  display: "none",
                },
                inputLabelWithFiles: { display: "none" },
                input: { display: "none" },
                }}
                inputContent={(file, extra) => {
                  const { t } = this.props;
                  if (extra.reject) {
                    return this.renderInputContent(t("only_file_type_allowed"));
                  }
                  return this.renderInputContent(t("upload_pdf"));
                }}
              />

              <div className="upload-button-content">
                <p className="upload-button-content__title">{t("upload_image")}</p>
                <p className="upload-button-content__description">{t("upload_image_frame")}</p>
                <input
                  type="file"
                  id="pdfFile"
                  onChange={(event) => this.imageSelectedKendu(event)}
                  style={{ display: "none" }}
                  accept="application/pdf"
                />
                <button className="btn-base fill-black link-s">
                <label htmlFor="pdfFile">
                  {this.state.kenduPdfFile || this.state.showPdfUploadMessage ? t("change_fa") : t("upload_fa")}
                </label>
                </button>
              </div>
            </div>

            {/* Image */}
            <div style={{ width:'100%', maxWidth: '300px', maxHeight: '300px', }} className={`specia__request--image hidden-preview hover__enlarge ${(data?.support.new_image !== undefined || visual) ? 'image-uploaded' : ''}`}>
              {data?.line?.special_request_image && (
                <ul className="tab-bar">
                  <li className={!this.state.showImage ? "active" : ""} onClick={() => this.setState({ showImage: false })}>
                    {t('Kendu')}
                  </li>
                  <li className={this.state.showImage ? "active" : ""} onClick={() => this.setState({ showImage: true })}>
                    {t('Proposal')}
                  </li>
                </ul>
              )}

              {this.state.showImageUploadMessage ? (
                <div className="upload-success-message bg-success" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <span>{t("file_upload_correctly")}</span>
                </div>
              ) : (
                data?.support.new_image ? (
                  <img className="cursor-hand" src={`${data?.support.new_image}?v=${new Date().getTime()}`} alt="" />
                ) : (
                  this.renderInputContent(t("start_dragging_or_attaching_the_images"))
                )
              )}

              <Dropzone
                onChangeStatus={this.handleKenduUpload}
                getUploadParams={() => ({ url: 'no-upload' })}
                autoUpload={false}
                accept="image/*"
                maxFiles={1}
                styles={{
                  dropzone: {
                    borderRadius: 0,
                    borderWidth: 0,
                    borderStyle: "dashed",
                    borderColor: "#d5d9e4",
                    overflow: "hidden",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                  },
                  dropzoneReject: { borderColor: "red", backgroundColor: "red" },
                  dropzoneActive: {
                    borderColor: "#00a3da",
                    backgroundColor: "#00a3da",
                  },
                  inputLabel: {
                    color: "#00a3da",
                    fontFamily: "Inter",
                    fontSize: "1.6rem",
                    display: "none",
                  },
                  inputLabelWithFiles: { display: "none" },
                  input: { display: "none" },
                }}
                inputContent={(file, extra) => this.inputContent(extra)}
              />
              <div className="upload-button-content">
                <p className="upload-button-content__title">{t("upload_image")}</p>
                <p className="upload-button-content__description">{t("upload_image_frame")}</p>
                <input
                type="file"
                id="imageFile"
                onChange={(event) => this.imageSelectedKendu(event)}
                style={{ display: "none" }}
                accept="image/*"
                />
                <button className="btn-base fill-black link-s">
                <label htmlFor="imageFile">
                  {this.state.kenduImageFile || this.state.showImageUploadMessage ? t("Change Image") : t("upload_image")}
                </label>
                </button>
              </div>
            </div>
          </div>
          
          <div className="flex row-flex-end">
            <button className="btn-outline outline-primary link-m" onClick={this.handleSendData}>
              {t("send_data")}
            </button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="specia_request_row gap-16" style={{ display: 'flex', alignItems: 'center'}} >
            {/* PDF */}
            <div className="specia__request--image hidden-preview hover__enlarge" style={{ width:'100%', maxWidth: '300px', maxHeight: '300px', }}>
              {this.state.showPdfUploadMessage && (
                <div className="upload-success-message bg-success" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <span>{t("file_upload_correctly")}</span>
                </div>
              )}
              <Dropzone 
                onChangeStatus={this.handleKenduUpload}
                getUploadParams={() => ({ url: 'no-upload' })}
                autoUpload={false}
                accept="application/pdf"
                maxFiles={1}
                styles={{
                  dropzone: {
                    borderRadius: 0,
                    borderWidth: 0,
                    borderStyle: "dashed",
                    borderColor: "#d5d9e4",
                    overflow: "hidden",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                  },
                  dropzoneReject: { borderColor: "red", backgroundColor: "red" },
                  dropzoneActive: {
                    borderColor: "#00a3da",
                    backgroundColor: "#00a3da",
                  },
                  inputLabel: {
                    color: "#00a3da",
                    fontFamily: "Inter",
                    fontSize: "1.6rem",
                    display: this.state.kenduPdfFile ? "none" : "auto",
                  },
                  inputLabelWithFiles: { display: "none" },
                  input: { display: "none" },
                  }}
                  inputContent={(file, extra) => {
                    const { t } = this.props;
                    if (extra.reject) {
                      return this.renderInputContent(t("only_file_type_allowed"));
                    }
                    return this.renderInputContent(t("upload_pdf"));
                  }}
              />

              <div className="upload-button-content" style={{ position: "absolute", display: "flex", justifyContent: "flex-end", alignItems: "flex-end", height: "90%", right: 10 }}>
                <button className="btn-base fill-black link-s">
                <label htmlFor="pdfFile">
                  {this.state.kenduPdfFile || this.state.showPdfUploadMessage ? t("change_fa") : t("upload_fa")}
                </label>
                </button>
              </div>
            
            </div>

            {/* Image */}
            <div className="specia__request--image hidden-preview hover__enlarge" style={{ width:'100%', maxWidth: '300px', maxHeight: '300px', }}>
              {data?.line?.special_request_image && (
                <ul className="tab-bar">
                  <li className={!this.state.showImage ? "active" : ""} onClick={() => this.setState({ showImage: false })}>
                    {t('Kendu')}
                  </li>
                  <li className={this.state.showImage ? "active" : ""} onClick={() => this.setState({ showImage: true })}>
                    {t('Proposal')}
                  </li>
                </ul>
              )}

              {this.state.showImageUploadMessage && (
                <div className="upload-success-message bg-success" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <span>{t("file_upload_correctly")}</span>
                </div>
              )}
              {/* Para subir imagen */}
              <Dropzone
                onChangeStatus={this.handleKenduUpload}
                getUploadParams={() => ({ url: 'no-upload' })}
                autoUpload={false}
                accept="image/*"
                maxFiles={1}
                styles={{
                  dropzone: {
                    borderRadius: 0,
                    borderWidth: 0,
                    borderStyle: "dashed",
                    borderColor: "#d5d9e4",
                    overflow: "hidden",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                  },
                  dropzoneReject: { borderColor: "red", backgroundColor: "red" },
                  dropzoneActive: {
                    borderColor: "#00a3da",
                    backgroundColor: "#00a3da",
                  },
                  inputLabel: {
                    color: "#00a3da",
                    fontFamily: "Inter",
                    fontSize: "1.6rem",
                    display: this.state.kenduImageFile ? "none" : "auto",
                  },
                  inputLabelWithFiles: { display: "none" },
                  input: { display: "none" },
                }}
                inputContent={(file, extra) => this.inputContent(extra)}
              />

              <div className="upload-button-content"  style={{ position: "absolute", display: "flex", justifyContent: "flex-end", alignItems: "flex-end", height: "90%", right: 10 }}>
                <button className="btn-base fill-black link-s">
                <label htmlFor="imageFile">
                  {this.state.kenduImageFile || this.state.showImageUploadMessage ? t("Change Image") : t("upload_image")}
                </label>
                </button>
              </div>

            </div>
          </div>
          <div className="flex row-flex-end">
            <input
              type="file"
              id="pdfFile"
              onChange={(event) => this.imageSelectedKendu(event)}
              style={{ display: "none" }}
              accept="application/pdf"
            />
            <input
              type="file"
              id="imageFile"
              onChange={(event) => this.imageSelectedKendu(event)}
              style={{ display: "none" }}
              accept="image/*"
            />
            <button className="btn-outline outline-primary link-m" onClick={this.handleSendData}>
              {t("send_data")}
            </button>
          </div>
        </>
      );
    }
  }

  renderClientImage() {
    const { t } = this.props;
    const {  data } = this.props.processing_details_state;
    if (this.state.uploading) {
      return <Spinner message={t("uploading_aaff")} />;
    }

    if (data?.line.special_request_image !== undefined ) {
      return (
        <>
          {/* TODO -> Desarrollo: 'La clase hover__enlarge aparecerá solo cuando contenga una imagen y esta se pueda ampliar' */}
          <div className={`specia__request--image hidden-preview hover__enlarge ${(data?.support.new_image !== undefined) ? 'image-uploaded' : ''}`}>
            <ul className="tab-bar">
              <li className={!this.state.showImage ? "active" : ""} onClick={() => this.setState({ showImage: false })}>{t('Kendu')}</li>
              <li className={this.state.showImage ? "active" : ""} onClick={() => this.setState({ showImage: true })}>{t('Proposal')}</li>
         
            </ul>
            {/* TODO -> Desarrollo: 'Si no hay imagen, se pone el siguiente div' */}
            {data?.line.special_request_image ? (
              <img className="cursor-hand" src={data?.line.special_request_image} alt="" />
            ) : (
              this.renderInputContent(t("No image available"))
            )}
            </div>
        </>
      );
    } 
  }

  
  renderProposalWithKenduUpload() {
    const { t } = this.props;
    const { data } = this.props.processing_details_state;
    
    if (data?.line.special_request_image !== undefined) {
      return (
        <>
          <div className="specia_request_row gap-16" style={{ display: 'flex', alignItems: 'center'}} >
            {/* Sección para subida de PDF - siempre igual independientemente del estado de showImage */}
            <div style={{ width:'100%', maxWidth: '300px', maxHeight: '300px', }} className={`specia__request--image hidden-preview hover__enlarge ${(data?.support.new_image !== undefined) ? 'image-uploaded' : ''}`}>
              {this.state.showPdfUploadMessage ? (
                <div className="upload-success-message bg-success" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <span>{t("file_upload_correctly")}</span>
                </div>
              ) : (
                data?.support.new_image ? (
                  <img className="cursor-hand" src={`${data?.support.new_image}?v=${new Date().getTime()}`} alt="" />
                ) : (
                  this.renderInputContent(t("upload_pdf"))
                )
              )}
              
              <Dropzone 
                onChangeStatus={this.handleKenduUpload}
                getUploadParams={() => ({ url: 'no-upload' })}
                autoUpload={false}
                accept="application/pdf"
                maxFiles={1}
                styles={{
                  dropzone: {
                    borderRadius: 0,
                    borderWidth: 0,
                    borderStyle: "dashed",
                    borderColor: "#d5d9e4",
                    overflow: "hidden",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                  },
                  dropzoneReject: { borderColor: "red", backgroundColor: "red" },
                  dropzoneActive: {
                    borderColor: "#00a3da",
                    backgroundColor: "#00a3da",
                  },
                  inputLabel: {
                    color: "#00a3da",
                    fontFamily: "Inter",
                    fontSize: "1.6rem",
                    display: "none",
                  },
                  inputLabelWithFiles: { display: "none" },
                  input: { display: "none" },
                }}
                inputContent={(file, extra) => {
                  const { t } = this.props;
                  if (extra.reject) {
                    return this.renderInputContent(t("only_file_type_allowed"));
                  }
                  return this.renderInputContent(t("upload_pdf"));
                }}
              />
              
              <div className="upload-button-content">
                <p className="upload-button-content__title">{t("Upload PDF")}</p>
                <p className="upload-button-content__description">{t("upload_pdf")}</p>
                <input
                  type="file"
                  id="pdfFile"
                  onChange={(event) => this.imageSelectedKendu(event)}
                  style={{ display: "none" }}
                  accept="application/pdf"
                />
                <button className="btn-base fill-black link-s">
                  <label htmlFor="pdfFile">
                    {this.state.kenduPdfFile || this.state.showPdfUploadMessage 
                      ? t("change_fa") 
                      : t("upload_fa")}
                  </label>
                </button>
              </div>
            </div>

            {/* Imagen de la propuesta con funcionalidad de subida de Kendu */}
            <div style={{ width:'100%', maxWidth: '300px', maxHeight: '300px', }} className={`specia__request--image hidden-preview hover__enlarge ${(data?.support.new_image !== undefined) ? 'image-uploaded' : ''}`}>
              <ul className="tab-bar">
                <li className={!this.state.showImage ? "active" : ""} onClick={() => this.setState({ showImage: false })}>{t('Kendu')}</li>
                <li className={this.state.showImage ? "active" : ""} onClick={() => this.setState({ showImage: true })}>{t('Proposal')}</li>
              </ul>
              
              {this.state.showImageUploadMessage ? (
                <div className="upload-success-message bg-success" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <span>{t("file_upload_correctly")}</span>
                </div>
              ) : (
                data?.line.special_request_image ? (
                  <img className="cursor-hand" src={data?.line.special_request_image} alt="" />
                ) : (
                  this.renderInputContent(t("start_dragging_or_attaching_the_images"))
                )
              )}
              
              {/* Solo mostrar el Dropzone para imágenes cuando no estamos en modo Proposal */}
              {!this.state.showImage && (
              <Dropzone
                onChangeStatus={this.handleKenduUpload}
                getUploadParams={() => ({ url: 'no-upload' })}
                autoUpload={false}
                accept="image/*"
                maxFiles={1}
                styles={{
                  dropzone: {
                    borderRadius: 0,
                    borderWidth: 0,
                    borderStyle: "dashed",
                    borderColor: "#d5d9e4",
                    overflow: "hidden",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                  },
                  dropzoneReject: { borderColor: "red", backgroundColor: "red" },
                  dropzoneActive: {
                    borderColor: "#00a3da",
                    backgroundColor: "#00a3da",
                  },
                  inputLabel: {
                    color: "#00a3da",
                    fontFamily: "Inter",
                    fontSize: "1.6rem",
                    display: "none",
                  },
                  inputLabelWithFiles: { display: "none" },
                  input: { display: "none" },
                }}
                inputContent={(file, extra) => this.inputContent(extra)}
              />
              )}
            </div>
          </div>
          
          <div className="flex row-flex-end">
            <button className="btn-outline outline-primary link-m" onClick={this.handleSendData}>
              {t("send_data")}
            </button>
          </div>
        </>
      );
    }
    return null;
  }

  renderContentTop() {
    const { t } = this.props;
    const { data } = this.props.processing_details_state;

    return (
      <ContentTop>
        <article className="detail-top">
          <div className="detail-top--title">
            {/* TODO -> Desarrollo: 'Hay un componente que hace esto: BackArrow ' */}
            <div className="btn-back v-center">
              <button
                className="center"
                onClick={() => this.props.history.push(`/kendu_processing/`)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-arrow-left"
                >
                  <line x1="19" y1="12" x2="5" y2="12"></line>
                  <polyline points="12 19 5 12 12 5"></polyline>
                </svg>
              </button>
            </div>
            <div className="title-detail flex gap-8 col-center">
              <h2>{data?.store.name}</h2>
              <span className="state state--department">
                {data?.store.customer_shop_no}
              </span>
              <span className="state state--blue">{data?.support.section}</span>
            </div>
          </div>
          <div className="flex col-center">
           
            
			<RoleGuard roles={[Role.KENDU_DESIGN,Role.ADMIN,Role.BACKDOOR]}>
          <button
              className="btn-outline outline-primary link-m"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                this.setState({ showRemoveConfirmation: true });
              }}
            >
              {t("remove_support_from_campaign")}
            </button>
      </RoleGuard>
      
      <RoleGuard roles={[Role.KENDU_DESIGN,Role.ADMIN,Role.BACKDOOR]}>
          <button
              className="btn-outline outline-primary link-m"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                this.setState({ showEditSupport: true });
              }}
            >
              {t("edit_support")}
            </button>
        </RoleGuard>

        {<RoleGuard roles={[Role.ADMIN,Role.BACKDOOR]}>
          <button
              className="btn-outline outline-primary link-m"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                this.setState({ showCancelSpecialRequest: true });
              }}
            >
              {t("cancel_special_request")}
            </button>
      </RoleGuard>}
            <button
              className="btn-base fill-primary link-m"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                this.props.markAsSolved(data!, this.props);
              }}
            >
              {t("confirm_image_kendu_processing")}
            </button>

          </div>
        </article>
      </ContentTop>
    );
  }

  departmentHandler(value: string | undefined) {
    this.props.updateDeparmentValue(value);
  }

  priorityHandler(value: string | undefined) {
    this.props.updatePriority(value);
  }

  getPriorityValues() {
    let data = [];
    for (let i = 1; i < 100; i++) {
      data.push({ name: i.toString(), value: i.toString() } as SelectOption);
    }
    return data;
  }

  renderTabDetailTop() {
    const { t } = this.props;
    const { showImage } = this.state;
    const isKenduGlobalMessage = this.getKenduGlobalMessage();
    const { data } = this.props.processing_details_state;
    const { production_languages, departments } = this.props.data_types_state;

    const department = departments.find(
      (d) => d.value.toString() === data?.support?.section?.toString()
    );

    const language = production_languages.find(
      (lan) =>
        lan.value.toString() === data?.support.production_language?.toString()
    );

    return (
      <>
        <section className="specia__request">
          <article className="flex flex-column gap-24">
            {
              !showImage ? (
                isKenduGlobalMessage ? this.renderKenduImage() : this.renderImage()
              ) : (
                isKenduGlobalMessage ? this.renderProposalWithKenduUpload() : this.renderClientImage()
              )
            }
          </article>
          <article className="specia__request--details">
            <ul className="request__list">
              {/* TODO -> Desarrollo: 'Alguno de estos li pueden no aparecer siempre' */}
              <li>
                <h6>{t("campaign_line")}</h6>
                <p>{data?.line.id}</p>
              </li>
              <li>
                <h6>{t("creation_date")}</h6>
                <p> {data?.line.sr_date.replace("T00:00:00.000Z", "")} </p>
              </li>
              <li>
                <h6>{t("support_number")}</h6>
                <p> {data?.support.no_soporte_cliente} </p>
              </li>
              <li>
                <h6>{t("support_measure")}</h6>
                <p>
                  {data?.support.width}mm x {data?.support.height}mm
                </p>
              </li>
              {data?.support.production_language !== "" && (
                <li>
                  <h6>{t("language")}</h6>

                  <p>
                    {language !== undefined
                      ? language?.name
                      : data?.support.production_language}
                  </p>
                </li>
              )}

              <li>
                <h6>{t("support_type")}</h6>
                <p>{data?.support.support_type}</p>
              </li>
              <li>
                <h6>{t("textile_type")}</h6>
                <p>{data?.support.textil_type}</p>
              </li>
              <li>
                <h6>{t("department")}</h6>
                <p>{department?.name}</p>
              </li>

              <li>
                <h6>{t("priority")}</h6>
                <p>{data?.support.priority}</p>
              </li>
              {data?.store.new_trend && (
                <li>
                  <h6>{t("New Trend")}</h6>
                  <p>{t("yes")}</p>
                </li>
              )}

              {data?.line.comment && (
                <li className="mix">
                  <h6>{t("comments")}</h6>
                  <p>{data?.line.comment}</p>
                </li>
              )}
            </ul>
            {
              /* TODO -> Desarrollo: '.directory no aparece siempre' */
              data?.line.directory && (
                <article className="directory">
                  <div className="flex flex-column gap-24">
                    <h4>{t("directory")}</h4>
                  </div>
                  <div>
                    <ul className="request__list">
                      <li>
                        <h6>{t("list_of_departments")}</h6>
                        <p>{data.line.directory}</p>
                      </li>
                      <li>
                        <h6>{t("floor_plant_to_highlight")}</h6>
                        <p>{data.line.directory2?.highlight_floor}</p>
                      </li>
                      <li>
                        <h6>{t("number_of_floors")}</h6>
                        <p> {data.line.directory2?.floors} </p>
                      </li>
                      <li>
                        <h6>{t("language")}</h6>
                        <p>{data.line.directory2?.language}</p>
                      </li>
                      <li>
                        <h6>{t("rrss_wifi")}</h6>
                        <p>{data.line.directory2?.rrss_wifi}</p>
                      </li>
                      <li>
                        <h6>{t("returns_exchanges")}</h6>
                        <p>{data.line.directory2?.returns_exchanges}</p>
                      </li>
                      <li>
                        <h6>{t("Click&Collect")}</h6>
                        <p>{data.line.directory2?.click_collect}</p>
                      </li>
                      {data.line.directory2?.click_collect_floor!==''&& <li>
                        <h6>{t("Click&Collect Floor")}</h6>
                        <p>{data.line.directory2?.click_collect_floor}</p>
                      </li>}
                     
                    </ul>
                  </div>
                </article>
              )
            }
          </article>
        </section>
      </>
    );
  }

  renderRemove() {
    const { t } = this.props;
    const { showRemoveConfirmation } = this.state;
    const { data } = this.props.processing_details_state;
    let rol=get_role(get_user_token());
    if (showRemoveConfirmation) {
      return (
        <ConfirmationPopUp
          onClose={() => this.setState({ showRemoveConfirmation: false })}
          onSubmit={() => {
            this.setState({ showRemoveConfirmation: false });

            this.setState({ api_loading: true });
            remove_support_campaign(data?.line.id).then(() => {
              if(rol===Role.ADMIN|| rol===Role.BACKDOOR){
                this.props.history.push("/stores/" + data?.line.shop_id);
                window.location.reload();
              }else if(rol===Role.KENDU_DESIGN){
                this.props.history.push("/kendu_processing");
                window.location.reload();
              }
              
            });
          }}
          title={t("confirm_remove_from_campaign")}
          change_text={
            t("remove_support_from_campaign") +
            " " +
            data?.support.no_soporte_cliente
          }
        />
      );
    }
  }

  renderCancel(){
    const { t } = this.props;
    const { showCancelSpecialRequest } = this.state;
    const { data } = this.props.processing_details_state;
    if (showCancelSpecialRequest) {
      return (
        <ConfirmationPopUp
          onClose={() => this.setState({ showCancelSpecialRequest: false })}
          onSubmit={() => {
            this.setState({ showCancelSpecialRequest: false });

            this.setState({ api_loading: true });
            cancel_sr(data?.line.id, data?.line.campaign_id).then(() => {
              
                this.props.history.push("/kendu_processing");
                window.location.reload();
             
              
            });
          }}
          title={t("confirm_cancel_special_request")}
          change_text={
            t("cancel_special_request") +
            " " +
            data?.support.no_soporte_cliente
          }
        />
      );
    }
  }

  renderEditSupport() {
    const { showEditSupport } = this.state;
    const { data } = this.props.processing_details_state;
    const {  departments } = this.props.data_types_state;
    if (showEditSupport) {
      return (
        <EditSupportKenduProcessing
          support={data?.support}
          sections={departments}
          onClose={() => this.setState({ showEditSupport: false })}
		  onSubmit={(support)=>{
			this.setState({ api_loading: true });
			update_support_kendu_processing(data?.line.campaign_id,data?.line.id,support.id,support.section,support.priority).then(() => {
				this.setState({ api_loading: false });
				this.setState({ showEditSupport: false });
				this.props.getData(
					this.props.match.params.campaign_id,
					this.props.match.params.support_id
				  );
			});
			
		  }}
        />
      );
    }
  }

  render() {
    const { t } = this.props;
    const { api_loading } = this.state;
    const { loading } = this.props.processing_details_state;

    if (loading || api_loading) {
      return <Spinner message={t("loading")} />;
    }
    return (
      <>
        {this.renderContentTop()}
        {this.renderTabDetailTop()}
        {this.renderRemove()}
        {this.renderCancel()}
        {this.renderEditSupport()}
      </>
    );
  }
}

export default withTranslation()(connector(Index));
